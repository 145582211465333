.connected-box {
    height: 26px;
    white-space: nowrap;
    box-sizing: inherit;
    display: flex;
    align-items: center;
    position: absolute;
    right: 200px;
    top: 3px;
}

.connected-box:hover {
    background-color: #475569;
    cursor: pointer;
}

.connected-txt {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.popup-arrow {
    color: #94a3b8;
}

.tooltip {
    z-index: 1;
    margin-top: 30px;
    margin-left: -115px;
    position: absolute;
    visibility: hidden;
    opacity: 0.75;
}

.connected-popup:hover {
    cursor: pointer;
}

.connected-popup:hover .tooltip {
    visibility: visible;
}