.App {
  font-family: "Poppins", sans-serif;
}

.title-txt {
  font-family: "AGFont";
  letter-spacing: 0.1em;
}

::-webkit-scrollbar {
  background: none;
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background: #64748b;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:active {
  background: #475569;
  border-radius: 10px;
}

.tabs-ui {
  justify-content: space-between !important;
  display: flex !important;
  box-sizing: inherit;
  min-height: 2.15rem;
}

.custom-button {
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
}

.custom-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.custom-button:before {
  content: '';
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.1);
  transform: translateY(-100%);
  transition: all 0.3s ease;
}

.custom-button:hover:before {
  transform: translateY(0);
}

.social-button {
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
}

.social-button:hover {
  transform: translateY(-4px);
}

.social-button:before {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translateY(-100%);
  transition: all 0.3s ease;
}

.social-button:hover:before {
  transform: translateY(0);
}