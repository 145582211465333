@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

@font-face {
    font-family: "AGFont";
    src: local("AGFont"),
        url(../public/fonts/AttackGraffiti.woff) format("woff"),
        local("AGFont"),
        url(../public/fonts/AttackGraffiti.ttf) format("truetype");
}