.program-body {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    font-family: "Poppins", sans-serif;
}

.panel {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex: flex-auto;
}

.explorer-title {
    width: 96%;
    display: flex;
    align-items: center;
    padding: 16px 24px 16px;
    justify-content: space-between;
    margin: auto;
    margin-top: 6px;
}

.panel-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    column-gap: 4px;
}

.explorer {
    height: 100%;
    overflow-y: auto;
}

.explorer-view {
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
    width: 100%;
}

.explorer-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    margin: 0;
}

.explorer-content {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 380px;
    height: 100%;
    box-sizing: border-box;
    overflow-y: auto;
    align-items: center;
}

.icon-container {
    height: 100vh !important;
    display: flex !important;
    flex-direction: column !important;
    align-items: center;
    flex-flow: column nowrap;
}

.icon-box {
    align-items: center;
    display: flex;
    vertical-align: middle;
}

.icon {
    width: 40px;
    height: 40px;
    padding: 4px;
    margin: auto;
    border-style: none;
}

.c-icon:hover {
    animation-name: spin;
    animation-duration: 2.5s;
    animation-iteration-count: infinite;
    transform-origin: 50% 50%;
    display: inline-block;
}

.icon-box:hover {
    box-shadow: 0px 0px 14px -7px #efebe9;
}

.box-inherit {
    box-sizing: inherit;
}

.main-view {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    position: relative;
    box-sizing: inherit;
}

.main-wrapper {
    flex: 1;
    min-height: 100px;
    box-sizing: inherit;
    display: block;
}

.main-container {
    height: 100%;
    width: 100%;
    box-sizing: inherit;
    display: block;
    overflow-y: auto;
}

.socials-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: absolute;
    right: 0;
}

.socials-box>.socials {
    margin: 5px;
    width: 25px;
}

.socials:hover {
    color: #94a3b8;
}

.socials-2:hover {
    color: #414850;
}

#c-input {
    background: transparent;
    border: none;
    outline: none;
    font-size: 12px;
    width: 100%;
}

#c-input:active {
    border: none;
    outline: none;
}

#c-input:active,
input-box {
    background-color: #475569;
    border-radius: 10px;
}

input[type="text"]::placeholder {
    padding-left: 6px;
}

input[type="number"]::placeholder {
    padding-left: 6px;
}

input[type="number"] {
    -webkit-appearance: none;
    -moz-appearance: textfield;
    appearance: none;
}


input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

textarea[type="text"]::placeholder {
    padding-left: 6px;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}