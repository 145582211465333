.tabs-fit {
    max-width: fit-content;
    width: 97%;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    box-sizing: inherit;
}

.version-box {
    display: flex;
    flex-direction: column;
    overflow-wrap: break-word;
    align-items: left;
    margin-right: 0px;
}

.version-popup {
    position: absolute;
    width: 360px;
    height: 400px;
    margin-left: -10px;
    margin-top: -2px;
    overflow-y: scroll;
}

.version-popup::-webkit-scrollbar {
    width: 9px;
}

.version-content ul {
    padding-left: 4px;
}

.version-content ul>*:not(:last-child) {
    line-height: 1.2;
    margin-top: 6px;
    padding: 4px;
    width: 99%;
}

.version-content ul>*:first-child {
    margin-top: 8px;
    padding-top: 6px;
    border-top: 1px dashed #cbd5e1;
}

.version-content ul>*:last-child {
    margin-bottom: 8px;
    line-height: 1.2;
    margin-top: 6px;
    width: 99%;
    padding: 4px;
    border-bottom-right-radius: 10px;
}

.version-content ul>*:nth-child(2n) {
    background-color: #aebac9;
}