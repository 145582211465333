.config-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.config-box2 {
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    width: 100%;
}

.config-box3 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.config-label {
    width: 50%;
}

.config-input {
    width: 50%;
}

.config-input-percent {
    width: 15%;
}

.config-input2 {
    width: 100%;
    height: 80px;
}

.panel-box {
    width: 95%;
    height: 99%;
    overflow-x: hidden;
    overflow-wrap: break-word;
}

.panel-box::-webkit-scrollbar {
    display: none;
}